import React from 'react';
import Helmet from 'react-helmet';

const SchemaMarkup = (props) => {
  const { items } = props;

  const itemsString = JSON.stringify(items);

  return (
    <Helmet>
      <script type="application/ld+json">{itemsString}</script>
    </Helmet>
  );
};

export default SchemaMarkup;
