import React from 'react';

const Footer = ({}) => {
  return (
    <footer className="py-8">
      <div className="container text-center">
        <div className="text-gray-600">© 2019 ContactBubble. All Rights Reserved.</div>
      </div>
    </footer>
  );
};

export default Footer;
