import { lowerCase } from 'lodash';

const validateItem = (values, item) => {
  if (typeof item === 'string') return !!values[item];
  if (!item.validation) return !!values[item.key];

  if (item.validation === 'email') {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(String(values[item.key]).toLowerCase());
  }
};

export const validate = (values, items) => {
  return items.reduce((errorsReducer, item) => {
    const itemKey = typeof item === 'string' ? item : item.key;

    const isValid = validateItem(values, item);

    if (!isValid) {
      const itemLabel = typeof item === 'string' ? lowerCase(item) : item.label;

      return Object.assign({}, errorsReducer, {
        [itemKey]: `A valid ${itemLabel} is required`,
      });
    }

    return errorsReducer;
  }, {});
};

export const readDataUrl = (file, callback) => {
  const reader = new window.FileReader();

  reader.onloadend = function() {
    callback(reader.result);
  };

  reader.readAsDataURL(file);
};

export const getBase64File = (file) => {
  return new Promise((resolve) => {
    readDataUrl(file, (res) => {
      const { name, size, type } = file;

      resolve(
        Object.assign(
          {},
          {
            base64: res.split(',')[1],
            name,
            size,
            type,
          }
        )
      );
    });
  });
};
