import buildUrl from 'build-url';

import { APP_BASE_URL } from '../../helpers';

export const getAmplitudeDeviceId = (slug) => {
  if (typeof window === 'undefined' || !window.amplitude || !window.amplitude.options) return undefined;

  const { deviceId } = window.amplitude.options;

  return deviceId;
};

export const navigateToPlatform = (path, queryParams = {}) => {
  const isFullUrl = path.indexOf('http') > -1;
  const href = isFullUrl ? path : `${APP_BASE_URL}${path}`;

  if (typeof window === 'undefined') return;

  const amplitudeDeviceId = getAmplitudeDeviceId();

  if (!amplitudeDeviceId) return;

  const platformLink = buildUrl(href, {
    queryParams: Object.assign({}, queryParams, {
      ...(amplitudeDeviceId && { amp_device_id: amplitudeDeviceId }),
    }),
  });

  window.location.href = platformLink;
};
