import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

const propTypes = {
  field: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
};

const FieldError = (props) => {
  const { formik, field } = props;
  const { errors, touched } = formik;

  if (!touched[field] || !errors[field]) return false;

  return <div className="text-red text-sm mb-4">{errors[field]}</div>;
};

FieldError.propTypes = propTypes;

export default connect(FieldError);
