import React from 'react';
import Link from 'gatsby-link';

import icon from '../../../assets/icon.svg';
import logo from '../../../assets/logo-horizontal.svg';

import LinkToPlatform from '../../LinkToPlatform';

const Header = () => {
  return (
    <header className="fixed top-0 left-0 w-full h-16 z-50 bg-white shadow-xl">
      <div className="container h-full flex items-center justify-between">
        <div className="flex-shrink">
          <Link to="/" className="block" style={{ transform: 'translateY(25%)' }}>
            <span className="hidden md:inline-block">
              <img src={logo} alt="" width="225" />
            </span>
            <span className="inline-block md:hidden">
              <img src={icon} alt="" width="32" />
            </span>
          </Link>
        </div>
        <div className="flex-shrink">
          <nav>
            <ul className="mb-0 flex flex-padded-sm items-center text-sm md:text-normal">
              <li className="hidden md:block">
                <Link className="md:text-lg text-primary-500 hover:text-primary-700" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="md:text-lg text-primary-500 hover:text-primary-700" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  className="md:text-lg text-primary-500 hover:text-primary-700"
                  to="/our-story"
                >
                  Our Story
                </Link>
              </li>
              <li className="hidden md:block">
                <LinkToPlatform
                  className="md:text-lg text-primary-500 hover:text-primary-700"
                  to="/sign-in"
                >
                  Sign in
                </LinkToPlatform>
              </li>
              <li>
                <LinkToPlatform to="/sign-up" className="button button-primary">
                  Sign up
                  {' '}
                  <span className="hidden md:inline-block">for free</span>
                </LinkToPlatform>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
