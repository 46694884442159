import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

const FluidImage = (props) => {
  const {
    altText, className, image, imgStyle, style,
  } = props;
  if (!image || !image.childImageSharp || !image.childImageSharp.fluid) return false;

  return (
    <Image
      className={className}
      fluid={image.childImageSharp.fluid}
      alt={altText}
      imgStyle={imgStyle}
      style={style}
    />
  );
};

export default FluidImage;

export const fluidImage = graphql`
  fragment FluidIconImage on File {
    childImageSharp {
      fluid(maxWidth: 128, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment FluidSmallImage on File {
    childImageSharp {
      fluid(maxWidth: 250, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment FluidMediumImage on File {
    childImageSharp {
      fluid(maxWidth: 600, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment FluidLargeImage on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
