import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import '../../stylesheets/main.css';
import logo from '../../assets/logo-vertical.svg';

import SchemaMarkup from '../SchemaMarkup';

import Footer from './components/Footer';
import Header from './components/Header';

import favicon from '../../assets/icon.png';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func])
    .isRequired,
};

const defaultProps = {
  isBlue: false,
};

class Layout extends Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.contactbubble.com/embed.js';
    document.body.appendChild(script);

    window.contactbubbleSettings = {
      app_id: 'zqWwbks6Nz6sj59pQ',
    };
  }

  render() {
    const { children, isDark } = this.props;

    return (
      <div className="site-container">
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Special+Elite"
            rel="stylesheet"
          />
          <link rel="icon" href={favicon} type="image/png" sizes="16x16" />

          <meta name="og:type" content="website" />
          <meta name="og:locale" content="nl" />
          <meta name="og:site_name" content="ContactBubble" />

        </Helmet>
        <SchemaMarkup
          items={{
            '@context': 'http://schema.org',
            '@type': 'WebApplication',
            '@id': 'contactbubble',
            applicationCategory: 'BusinessApplication',
            name: 'ContactBubble - A floating contact form for your website or app',
            operatingSystem: 'all',
            browserRequirements: 'Requires Javascript and HTML5 support',
            url: 'https://app.contactbubble.com',
            screenshot: logo,
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.88',
              reviewCount: '10',
            },
            offers: {
              '@type': 'AggregateOffer',
              offeredBy: {
                '@type': 'Organization',
                name: 'convertcalculator',
              },
              highPrice: '9',
              lowPrice: '0',
              offerCount: '2',
              priceCurrency: 'USD',
              priceSpecification: [
                {
                  '@type': 'UnitPriceSpecification',
                  price: '0.00',
                  priceCurrency: 'USD',
                  name: 'Free',
                },
                {
                  '@type': 'UnitPriceSpecification',
                  price: '9.00',
                  priceCurrency: 'USD',
                  name: 'Pro',
                  referenceQuantity: {
                    '@type': 'QuantitativeValue',
                    value: '1',
                    unitCode: 'MON',
                  },
                },
              ],
            },
            creator: {
              '@type': 'Organization',
              '@id': '#organization',
              url: 'https://www.contactbubble.com/',
              name: 'contactbubble',
              logo: {
                '@type': 'ImageObject',
                url: favicon,
                width: '500px',
                height: '500px',
              },
            },
          }}
        />
        <Header isDark={isDark} />
        <main className="pt-16">{children}</main>
        <Footer />
      </div>
    );
  }
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
