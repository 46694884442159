import React from 'react';
import { Field, Form, Formik } from 'formik';

import { FormResponse, validate } from './FormikHelpers';
import { navigateToPlatform } from './LinkToPlatform';

const SignUpForm = () => {
  const handleSubmit = (values) => {
    navigateToPlatform('/sign-up', { email: values.email });
  };

  return (
    <div>
      <h4 className="text-gray-600 mb-2">
        Start for
        {' '}
        <em className="border-b-2 border-gray-600">free</em>
      </h4>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <Form>
              <div className="flex">
                <div className="flex-grow">
                  <Field
                    className="rounded-r-none border-r-0 leading-normal text-lg"
                    type="email"
                    name="email"
                    placeholder="Your email address"
                  />
                </div>

                <div className="flex-shrink">
                  <button
                    className="button button-secondary rounded-l-none text-lg border border-primary-500"
                    type="submit"
                  >
                    Get started
                  </button>
                </div>
              </div>

              <div className="text-gray-500">No strings attached, no credit card required</div>

              <FormResponse />
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SignUpForm;
