import React from 'react';
import PropTypes from 'prop-types';

import { APP_BASE_URL } from '../../helpers';

import { navigateToPlatform } from './helpers';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
};

const LinkToPlatform = (props) => {
  const { children, className, to } = props;

  return (
    <a
      className={className}
      onClick={(ev) => {
        ev.preventDefault();

        const { href } = ev.target;

        navigateToPlatform(href);
      }}
      href={`${APP_BASE_URL}${to}`}
    >
      {children}
    </a>
  );
};

LinkToPlatform.propTypes = propTypes;
LinkToPlatform.defaultProps = defaultProps;

export default LinkToPlatform;
