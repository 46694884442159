import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

const propTypes = {
  formik: PropTypes.object.isRequired,
};

const FormResponse = (props) => {
  const { formik } = props;
  const { status } = formik;

  if (!status) return false;

  if (status.err) {
    return (
      <div className="callout alert">{status.err.reason || status.err.message || status.err}</div>
    );
  }

  if (status.success) {
    return <div className="callout success">{status.success}</div>;
  }

  return false;
};

FormResponse.propTypes = propTypes;

export default connect(FormResponse);
