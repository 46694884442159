import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import { getBase64File } from './helpers';

const propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

const FileField = (props) => {
  const { children, formik, name } = props;
  const { setFieldValue, values } = formik;

  return (
    <Fragment>
      <input
        name={name}
        id={name}
        type="file"
        className="hidden"
        onChange={(ev) => {
          const { files } = ev.target;

          if (files && files.length) {
            getBase64File(files[0]).then((file) => {
              setFieldValue(name, file);
            });
          } else {
            setFieldValue(name, undefined);
          }
        }}
      />
      {values && values[name] ? (
        <p>
          {values && values[name].name}{' '}
          <button
            type="button"
            className="text-lg"
            onClick={() => {
              setFieldValue(name, undefined);
            }}
          >
            ×
          </button>
        </p>
      ) : (
        <label htmlFor={name} className="text-link mb-4">
          {children}
        </label>
      )}
    </Fragment>
  );
};

FileField.propTypes = propTypes;

export default connect(FileField);
